<template>
  <div class="container mx-2">
    <!-- Breadcrumb  -->
    <va-card>
      <va-card-content>
        <div style="display: flex">
          <va-breadcrumbs color="primary">
            <va-breadcrumbs-item :label="$t('menu.users')" />
            <va-breadcrumbs-item :label="$t('menu.clients')" />
          </va-breadcrumbs>
        </div>
      </va-card-content>
    </va-card>
    <va-inner-loading :loading="loading" icon="restart_alt">
      <div class="mt-3">
        <div class="flex">
          <va-card stripe stripe-color="primary">
            <va-card-content>
              <h1
                v-if="clients.length == 0"
                class="row align--center justify--center"
                style="font-size: 32px; height: 35vh"
              >
                {{ $t("no_clients") }}
                <va-icon class="material-icons" size="32px"> schedule </va-icon>
              </h1>
              <va-list v-else>
                <va-list-item
                  v-for="(client, index) in clients"
                  :key="client.name"
                  to=""
                >
                <strong>{{ index + offset + 1 }}.</strong>
                  <va-list-item-section avatar class="px-3">
                    <va-avatar>
                      <img v-if="client.img" :src="client.img" />
                      <div v-else>{{ client.name.substring(0, 1) }}</div>
                    </va-avatar>
                  </va-list-item-section>

                  <va-list-item-section>
                    <va-list-item-label>
                      {{ client.name }}
                    </va-list-item-label>

                    <va-list-item-label caption :lines="index + 1">
                      {{ client.tel }}
                    </va-list-item-label>

                    <va-list-item-label caption :lines="index + 1">
                      {{ client.email }}
                    </va-list-item-label>
                  </va-list-item-section>

                  <va-list-item-section icon>
                    <va-icon name="remove_red_eye" color="gray" />
                  </va-list-item-section>
                </va-list-item>
              </va-list>
              <va-pagination
                v-if="pages > 1"
                color="#0a4695"
                style="direction: ltr"
                class="float-end"
                v-model="currentPage"
                :visible-pages="3"
                :pages="pages"
              />
            </va-card-content>
          </va-card>
        </div>
      </div>
    </va-inner-loading>
  </div>
</template>

<script>
import { request, gql } from "graphql-request";
export default {
  data() {
    return {
      clients: [],
      clientsCount: null,
      pages: null,
      currentPage: 1,
      limit: 10,
      offset: 0,
      loading: true,
    };
  },
  async created() {
    // Pagination Logic
    try {
      const clientS_COUNT = gql`
        query {
          UsersCount(where: { user_role_id: 1 }) {
            count
          }
        }
      `;
      const counterResponse = await request(
        this.$store.state.appUrl,
        clientS_COUNT
      );
      this.clientsCount = counterResponse.UsersCount.count;
      this.pages = Math.ceil(this.clientsCount / this.limit);
      this.getclients();
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getclients() {
      this.loading = true;
      this.offset = (this.currentPage - 1) * this.limit;
      const GET_CLIENTS = gql`
        query ($limit: Int, $offset: Int) {
          clients: Users(
            where: { user_role_id: 1 }
            limit: $limit
            offset: $offset
          ) {
            id
            name
            email
            tel
          }
        }
      `;
      try {
        const response = await request(this.$store.state.appUrl, GET_CLIENTS, {
          limit: this.limit,
          offset: this.offset,
        });
        this.clients = response.clients;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("errors.network"),
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if (result.value) {
              location.reload();
            }
          });
      }
    },
  },
  watch: {
    async currentPage() {
      await this.getclients();
    },
  },
};
</script>

<style>
.va-list-item-section {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
